import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { LuEye, LuEyeOff, LuMail } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpAndLogin } from "reduxFiles/reducer/authSlices";
import * as authActions from "reduxFiles/actions/authActions";
import { useMediaQuery } from "react-responsive";
import { notify } from "utilities/notifications/Notify";
import { useState } from "react";
import FacebookGoogleLogin from "components/Auth/FacebookGoogleLogin";

const BookConsultationForm = ({
  form,
  loading,
  showLoginForm,
  setShowLoginForm,
  otpInput,
  handleSignUp,
  handleSubmitRequest,
  handleLogin,
}) => {
  const dispatch = useDispatch();
  // const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  // const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const verifyOtpLogin = async () => {
    const formData = new FormData(form.current);
    const data = {
      username: formData.get("email"),
      email_verified_otp: formData.get("email_verified_otp"),
    };
    console.log(data);
    try {
      const response = await authActions.verfiyOtpLogin(data);
      if (response.data) {
        if (response.data?.user?.is_active) {
          dispatch(verifyOtpAndLogin(response.data));
        } else {
          notify("User is not active", "error");
        }
        if (response.data?.user.id) {
          handleSubmitRequest(response.data.user.id);
        }
        // navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ minWidth: medium ? 400 : 300 }}>
      {!showLoginForm ?
        <Form ref={form} onSubmit={handleSignUp}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Full Name<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <Form.Control
              required
              className="py-2 body-text2"
              type="text"
              name="full_name"
              placeholder="Enter your Full Name"
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Email<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <LuMail size={20} color="#1A1C1F" />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="email"
                name="email"
                required
                placeholder="example@gmail.com"
                style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Phone Number<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="body-text2 inter-600"
                style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                +91
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type="text"
                name="mobile"
                required
                placeholder="9090909090"
                maxLength={10}
                pattern="[0-9]*"
                style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Password<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="cursor-pointer"
                style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type={showPassword ? "text" : "password"}
                name="password"
                required
                placeholder="**********"
                style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Confirm Password<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="cursor-pointer"
                style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2 body-text2"
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                required
                placeholder="**********"
                style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
                title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
              />
            </InputGroup>
          </Form.Group>
          {otpInput && (
            <Form.Group className="my-3">
              <Form.Label className="mb-1 body-text2 inter-500">
                OTP<sup style={{ color: "red" }}>*</sup>
              </Form.Label>
              <Row className="">
                <Col xs="6">
                  <Form.Control
                    required
                    className="py-2 body-text2"
                    type="text"
                    name="email_verified_otp"
                    placeholder="****"
                    style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                  />
                </Col>
                <Col xs="3">
                  <Button
                    variant="success"
                    className="w-100 text-white py-2"
                    style={{ borderRadius: 3 }}
                    onClick={() => verifyOtpLogin()}
                  >
                    Verify
                  </Button>
                </Col>
                <Col xs="3">
                  <Button
                    className="text-white w-100 py-2 px-3"
                    style={{ borderRadius: 3 }}
                  >
                    Resend
                  </Button>
                </Col>
              </Row>
              <Form.Text className="text-success">
                OTP has been sent to the email address
              </Form.Text>
            </Form.Group>
          )}
          <div className="text-center mb-2">
            <Button
              type="submit"
              disabled={loading}
              className="text-white w-100 mb-2"
              style={{ borderRadius: 3 }}
            >
              {loading && (
                <Spinner animation="border" size="sm" className="me-2" />
              )}
              {isAuthenticated ? "Request a Free Call Back" : "Verify"}
            </Button>
            <div className="body-text3 text-gray-900 text-center mb-2">
              Already have an account?{" "}
              <span
                className="inter-500 body-text2 cursor-pointer"
                onClick={() => {
                  setShowLoginForm(true);
                }}
              >
                Login
              </span>
            </div>
          </div>
        </Form>
        :
        <Form ref={form} className="my-3" onSubmit={handleLogin}>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Email ID<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <LuMail size={20} color="#1A1C1F" />
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2"
                type="email"
                name="username"
                required
                placeholder="example@gmail.com"
                style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1 body-text2 inter-500">
              Password<sup style={{ color: "red" }}>*</sup>
            </Form.Label>
            <InputGroup>
              <InputGroup.Text
                className="cursor-pointer"
                style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
              </InputGroup.Text>
              <Form.Control
                className="border-start-0 p-2"
                type={showPassword ? "text" : "password"}
                name="password"
                required
                placeholder="**********"
                style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
              />
            </InputGroup>
          </Form.Group>
          <Button
            type="submit"
            variant="primary"
            className="py-2 body-text2 border-0"
            style={{
              fontWeight: "600",
              color: "#fff",
              width: "100%",
              borderRadius: 3
            }}
          >
            {loading && <Spinner animation="border" size="sm" />}{" "}
            Login
          </Button>
          <div className="body-text3 text-gray-900 text-center my-2">
            Not a member?{" "}
            <span
              className="inter-500 body-text2 cursor-pointer"
              onClick={() => {
                setShowLoginForm(false)
              }}
            >
              Register
            </span>
          </div>
        </Form>
      }
    </div>
  );
};

export default BookConsultationForm;
