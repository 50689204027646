import React, { useEffect, useRef, useState } from "react";
import * as authActions from "reduxFiles/actions/authActions";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyOtpAndLogin } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { professional_icon, homeowner_icon } from "assets";

import UserTypeSelection from "./UserTypeSelection";
import ProfessionalTypeSelection from "./ProfessionalTypeSelection";
import UserSignup from "./UserSignup";
import PartnerSignup from "./PartnerSignup";
import { validateEmail } from "utilities/validateEmail";

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location.hash.substring(1);
  const hashValues = hash.split('|');

  const [isHomeOwner, setIsHomeOwner] = useState(true);
  const [step, setStep] = useState(1);
  const [serviceType, setServiceType] = useState("architect");
  const [loading, setLoading] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const dispatch = useDispatch();
  const form = useRef(null);

  const joinAs = [
    { id: 1, title: "Homeowner", icon: homeowner_icon, description: "Client looking to get my home interiors done through Interiokeys" },
    { id: 2, title: "Professional", icon: professional_icon, description: "Designer, brand, builder, contractors, PMC, etc looking to work with Interiokeys" }
  ]

  const serviceCategories = [
    { id: 1, title: "Designers", type: "architect", description: "Looking to create design inspirations. Architects, design firms, etc." },
    { id: 2, title: "Brands", type: "brand", description: "Looking to showcase products. Sanitaryware, lighting, flooring, etc." },
    { id: 3, title: "Builders", type: "builder", description: "Looking to list their properties. My Home, Suncity Builders, etc" },
    { id: 4, title: "Others (Execution)", type: "others", description: "PMC, Turnkey Contractors, OEM’s" },
  ]

  useEffect(() => {
    if (hashValues[0] === "homeowner") {
      setIsHomeOwner(true)
      setStep(3)
    } else if (hashValues[0] === "professional") {
      setIsHomeOwner(false)
      setStep(2)
    }
    if (hashValues[1]) {
      setServiceType(hashValues[1])
      setStep(3)
    }
  }, [location])

  const handleUserSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    if (validateEmail(formData.get("email"))) {
      if (formData.get("confirm_password") !== formData.get("password")) {
        notify("Passwords don't match!");
        setLoading(false);
        return;
      }
      formData.append("user_type", "customer");
      let data = {};
      formData.forEach((value, key) => (data[key] = value));
      try {
        const response = await authActions.signup(data);
        if (response.data) {
          setOtpInput(true);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(false);
      notify("Please enter a valid email id!", "error");
    }
  };

  const verifyOtpLogin = async () => {
    const formData = new FormData(form.current);
    const data = {
      username: formData.get("email"),
      email_verified_otp: formData.get("email_verified_otp"),
    };
    console.log(data);
    try {
      const response = await authActions.verfiyOtpLogin(data);
      if (response.data) {
        dispatch(verifyOtpAndLogin(response.data));
        notify("Logged in successfully!", "success")
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container
      fluid
      className="my-5 p-0 py-4"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100 bg-brand-25 d-flex justify-content-center align-items-center">
        <h1 className="h2 text-primary-500 inter-600 pb-3 pt-4">
          {step === 1 ?
            "Get started with Interiokeys!"
            :
            `Signup as Interiokeys ${isHomeOwner ? "Homeowner" : "Professional"}`
          }
        </h1>
      </div>
      {step === 1 ?
        <UserTypeSelection joinAs={joinAs} isHomeOwner={isHomeOwner} />
        :
        step === 2 ?
          <ProfessionalTypeSelection
            serviceCategories={serviceCategories}
            step={step}
            setStep={setStep}
            serviceType={serviceType}
            setServiceType={setServiceType}
          />
          : step === 3 ?
            isHomeOwner ?
              <UserSignup
                form={form}
                step={step}
                setStep={setStep}
                otpInput={otpInput}
                handleUserSignUp={handleUserSignUp}
                verifyOtpLogin={verifyOtpLogin}
                loading={loading}
              />
              :
              <PartnerSignup
                form={form}
                step={step}
                setStep={setStep}
                serviceType={serviceType}
                verifyOtpLogin={verifyOtpLogin}
              />
            :
            <></>
      }
    </Container>
  );
}

export default Signup;
