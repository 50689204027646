import { interiokeys_full_logo } from "assets";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const Footer = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const navigate = useNavigate();

  const [projects, setProjects] = useState([]);
  const [roomCategories, setRoomCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [professionalTypes, setProfessionalTypes] = useState([]);
  const [builders, setBuilders] = useState();

  const company_links = [
    { id: 1, title: "About Us", link: "about-us" },
    { id: 2, title: "Contact Us", link: "contact-us" },
    { id: 3, title: "Terms & Privacy", link: "terms-and-privacy" },
    { id: 4, title: "Our Blogs", link: "blogs" },
    { id: 5, title: "Careers", link: "careers" },
    { id: 6, title: "Trademark", link: "trademark" },
  ]

  const partner_with_us = [
    { id: 1, title: "Join as Professional", link: "signup#professional|architect" },
    { id: 2, title: "Join as Builder", link: "signup#professional|builder" },
    { id: 3, title: "Join as Brand", link: "signup#professional|brand" },
    { id: 4, title: "Join as OEM’s", link: "signup#professional|others" },
    { id: 5, title: "Join as PMC", link: "signup#professional|others" },
    { id: 6, title: "Join as Contractor", link: "signup#professional|others" },
  ]

  const services = [
    { id: 1, title: "Book Consultation", link: "consultation" },
    { id: 2, title: "Real Inspirations", link: "projects" },
    { id: 3, title: "Project Drawings", link: "project-drawings" },
  ]

  const fetchProjects = async () => {
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRoomCategories = async () => {
    try {
      const response = await authActions.fetchRoomCategoriesData();
      if (response.data) {
        setRoomCategories(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrandCategoriesByTypes = async () => {
    try {
      const response = await authActions.fetchBrandCategoriesByTypes();
      if (response.data) {
        setBrands([
          { id: 1, title: "Timber, Ply & Laminates", data: response.data.timbers_plywood_and_laminates },
          { id: 2, title: "Flooring", data: response.data.flooring },
          { id: 3, title: "Other Brands", data: response.data.others }
        ])
      }
    } catch (error) {
      console.log(error);
    }
  };


  const fetchProfessionalsByUserSubType = async () => {
    const userSubTypes = ["architecture-firm", "interior-designers", "architects"];
    const queryString = userSubTypes.map(slug => `slugs=${encodeURIComponent(slug)}`).join('&');
    try {
      const response = await authActions.fetchProfessionalsByUserSubType(queryString);
      if (response.data) {
        setProfessionalTypes([
          { id: 1, title: "Architecture Firm", data: response.data["architecture-firm"] },
          { id: 2, title: "Interior Designers", data: response.data["interior-designers"] },
          { id: 3, title: "Architects", data: response.data.architects }
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBuilders = async () => {
    try {
      const response = await authActions.fetchBuilders();
      if (response.data) {
        setBuilders(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchRoomCategories();
    fetchBrandCategoriesByTypes();
    fetchProfessionalsByUserSubType();
    fetchBuilders();
  }, []);

  return (
    <footer className="bg-gray-25">
      <Container>
        <Row className="justify-content-between py-5">
          <Col lg={4}>
            <div className="h3 inter-700">
              <Image
                src={interiokeys_full_logo}
                alt="Interiokeys logo"
                style={{ maxWidth: 150 }}
              />
            </div>
            <div className="mt-4">
              <div className="text-primary-500 body-title inter-600">
                Want to understand how our process works?
              </div>
              <div className="text-gray-600 body-text2 inter-400 mb-2">
                Get our PDF sent to your Email ID for the step-by-step explanation.
              </div>
              <div className="body-text2 d-flex flex-row border rounded p-1 mb-2 bg-white">
                <Form.Control
                  className="body-text2 geist w-50 flex-grow border-0"
                  size="sm"
                  placeholder="yourname@gmail.com"
                  style={{
                    padding: "10px",
                  }}
                ></Form.Control>
                <Button size="sm" className="text-white py-1" style={{ width: 100 }}>
                  Send Email
                </Button>
              </div>
            </div>
            <div>
              <FaFacebook size={24} color="#1877F2" />
              <FaLinkedin className="mx-3" size={24} color="#0A66C2" />
              <FcGoogle className="" size={24} color="#D95656" />
              <FaYoutube className="mx-3" size={24} color="#FF0302" />
            </div>
            <Row className="mt-5">
              <Col>
                <div className="text-gray-900 body-title inter-600 text-primary">Company</div>
                <div className="mt-1">
                  {company_links.map((company_link, index) => {
                    return (
                      <a
                        key={index + 1}
                        href={`${company_link.link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        <p className="text-gray-900 body-text2 inter-400 mb-0">{company_link.title}</p>
                      </a>
                    )
                  })}
                </div>
              </Col>
              <Col>
                <div className="text-gray-900 body-title inter-600 text-primary">Partner With Us</div>
                <div className="mt-1">
                  {partner_with_us.map((partner, index) => {
                    return (
                      <a
                        key={index + 1}
                        href={`${partner.link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        <p className="text-gray-900 body-text2 inter-400 mb-0">{partner.title}</p>
                      </a>
                    )
                  })}
                </div>
              </Col>
              <Col>
                <div className="text-gray-900 body-title inter-600 text-primary">Services</div>
                <div className="mt-1">
                  {services.map((service, index) => {
                    return (
                      <a
                        key={index + 1}
                        href={`${service.link}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        <p className="text-gray-900 body-text2 inter-400 mb-0">{service.title}</p>
                      </a>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </Col>
          {small && (
            <Col className="d-flex justify-content-end">
              <div className="me-5">
                <div className="text-gray-900 body-title inter-600 text-primary">Inspirations</div>
                <div className="mt-1 mb-2">
                  <div className="text-gray-900 body-text2 inter-600">Projects</div>
                  {projects?.map((item, index) => (
                    <div
                      key={index + 1}
                      className="cursor-pointer text-gray-900 body-text2 inter-400"
                      onClick={() =>
                        navigate(`/projects?property=${item.property_data.slug}`)
                      }
                    >
                      {item?.project_name}
                    </div>
                  ))}
                </div>
                <div>
                  <div className="text-gray-900 body-text2 inter-600">Spaces</div>
                  {roomCategories?.map((item, index) => (
                    <div
                      key={index + 1}
                      className="cursor-pointer text-gray-900 body-text2 inter-400"
                      onClick={() =>
                        navigate(`/spaces?room-caterogry=${item.slug}`)
                      }
                    >
                      {item?.title}
                    </div>
                  ))}
                </div>
              </div>
              <div className="me-5">
                <div className="text-gray-900 body-title inter-600 text-primary">Brands</div>
                {brands.map((brand, i) => {
                  return (
                    <div key={i + 1} className={`${i === 0 ? "mt-1" : ""} mb-2`}>
                      <div className="text-gray-900 body-text2 inter-600">{brand.title}</div>
                      {brand?.data?.map((item, index) => (
                        <div
                          key={index + 1}
                          className="cursor-pointer text-gray-900 body-text2 inter-400"
                          onClick={() =>
                            navigate(`/brands/${item.slug}`)
                          }
                        >
                          {item?.name}
                        </div>
                      ))}
                    </div>
                  )
                })}
              </div>
              <div className="me-5">
                <div className="text-gray-900 body-title inter-600 text-primary">Designers</div>
                {professionalTypes?.map((professional, i) => {
                  return (
                    <div key={i + 1} className={`${i === 0 ? "mt-1" : ""} mb-2`}>
                      <div className="text-gray-900 body-text2 inter-600">{professional.title}</div>
                      {professional?.data?.map((item, index) => (
                        <div
                          key={index + 1}
                          className="cursor-pointer text-gray-900 body-text2 inter-400"
                          onClick={() =>
                            navigate(`/professionals/architect/${item.slug}`)
                          }
                        >
                          {item?.company_name}
                        </div>
                      ))}
                    </div>
                  )
                })}
              </div>
              <div>
                <div className="text-gray-900 body-title inter-600 text-primary">Builders</div>
                {builders?.map((builder, index) => {
                  return (
                    <div
                      key={index + 1}
                      className="cursor-pointer text-gray-900 body-text2 inter-400"
                      onClick={() =>
                        navigate(`/professionals/builders/${builder.slug}`)
                      }
                    >
                      {builder?.company_name}
                    </div>
                  )
                })}
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
