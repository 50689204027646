import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { formatNumber } from "utilities/numberFormatter";
import ProductCard from "components/Cards/ProductCard";
import ShareModal from "components/General/ShareModal";
import SpacesSliderView from "./SpacesSliderView";
import * as authActions from "reduxFiles/actions/authActions";

import { MdOutlineFileDownload } from "react-icons/md";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";
import { notify } from "utilities/notifications/Notify";

const ProjectDetails = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const location = useLocation();
  const { state } = location;
  const user = useSelector((state) => state.auth.user);

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [projectDetails, setProjectDetails] = useState(null);
  const [error, setError] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);
  const [roomData, setRoomData] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [showAllRooms, setShowRooms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const fetchProject = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await authActions.fetchProjectBySlug(slug);
      if (response.data) {
        setProjectDetails(response?.data?.results[0]);
      } else {
        setError("Error while fetching project details. Try again later!");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const checkProjectIfSaved = async () => {
    try {
      const response = await authActions.checkProjectIfSaved(
        user.id,
        projectDetails.id
      );
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProject = async () => {
    try {
      const response = await authActions.saveProject(
        user.id,
        projectDetails.id
      );
      if (response.data) {
        setIsLiked(true);
        notify("Project saved successfully", "success");
      } else {
        notify("Failed to save project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProject = async () => {
    try {
      const response = await authActions.unsaveProject(
        user.id,
        projectDetails.id
      );
      if (response) {
        setIsLiked(false);
        notify("Project unsaved successfully", "success");
      } else {
        notify("Failed to unsave project, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const roomsInRow = xlarge ? 6 : large ? 4 : medium ? 3 : 2;

  useEffect(() => {
    if (showAllRooms) {
      setRooms(projectDetails?.rooms);
    } else {
      setRooms(projectDetails?.rooms?.slice(0, roomsInRow));
    }
  }, [projectDetails, showAllRooms]);

  useEffect(() => {
    if (slug && slug !== "undefined") {
      fetchProject();
    } else {
      navigate(-1);
    }
  }, [slug]);

  useEffect(() => {
    if (projectDetails?.id) {
      checkProjectIfSaved();
    }
  }, [projectDetails]);

  return (
    <div className="mt-5 pt-5 p-0">
      {loading ? (
        <Container
          className="d-flex justify-content-center align-items-center w-100"
          style={{ minHeight: "50vh" }}
        >
          <Spinner />
        </Container>
      ) : error ? (
        <Container
          className="d-flex justify-content-center align-items-center h3 inter-600 text-center"
          style={{ minHeight: "50vh" }}
        >
          {error}
        </Container>
      ) : (
        <div>
          <Container fluid className="d-flex justify-content-center">
            <Container className="d-flex flex-column flex-lg-row align-items-start p-3 m-0">
              <div
                className="d-flex justify-content-center align-items-center bg-white overflow-hidden"
                style={{ width: 350, height: 200, borderRadius: 12 }}
              >
                <Image
                  className="bg-white ms-auto"
                  width="auto"
                  height="200"
                  style={{
                    objectFit: "cover",
                  }}
                  src={projectDetails?.property_data?.property_img}
                  alt="Builder's Profile"
                />
              </div>
              <div className="w-100 ms-3 text-gray-900">
                <div className="d-flex align-items-center mb-2">
                  <div className="h3 inter-600">
                    {projectDetails?.project_name}/{projectDetails?.bhk}/{projectDetails?.property_name}
                  </div>
                  <div className="d-flex ms-auto me-lg-5">
                    <Button
                      className="d-flex justify-content-center align-items-center share-like-btn-100 p-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowShare(true);
                      }}
                    >
                      <FiShare2 size={20} />
                    </Button>
                    {user &&
                      // user?.user_type !== "builder" &&
                      user?.user_type !== "architect" &&
                      user?.user_type !== "brand" && (
                        <Button
                          className={`d-flex justify-content-center align-items-center share-like-btn-100 ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""
                            }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            isLiked ? handleUnsaveProject() : handleSaveProject();
                          }}
                        >
                          <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
                        </Button>
                      )}
                  </div>
                </div>
                <div className="body-text1 inter-400 mb-2">
                  <div className="">Layout Type: {projectDetails?.bhk}</div>
                  <div className="">Area: {projectDetails?.project_area} Sft.</div>
                  <div className="">Project Cost: {formatNumber(projectDetails?.project_cost_minimum)}</div>
                  <div className="">Architect: {projectDetails?.company_name}</div>
                  <div className="">Location: {projectDetails?.property_data?.location}</div>
                </div>
              </div>
            </Container>
          </Container>
          <Container className="">
            <div className="mt-4 mb-3">
              <span className="body-text inter-700">Image Description:</span>{" "}
              {projectDetails?.project_description}
            </div>
            <div>
              <Row className="mb-4">
                <Col lg={8}>
                  <div className="position-relative mb-4 mb-lg-0">
                    <img
                      className="img-fluid"
                      src={projectDetails?.rooms[0].media[0]?.file}
                      alt=""
                      style={{
                        width: "100%",
                        height: medium ? 450 : 300,
                        objectFit: "cover",
                        borderRadius: 12,
                      }}
                    />
                    <div
                      className="position-absolute"
                      style={{ bottom: 16, left: 6, zIndex: 1 }}
                    >
                      <div className="d-flex align-items-end overflow-scroll scrollbar-none">
                        <Button className="bg-gray-25 text-dark border-0 px-3 p-2 rounded inter-700 body-text2 mx-2">
                          {projectDetails?.rooms[0].room_type_data?.title} (
                          {projectDetails?.products?.length})
                        </Button>
                        <Button className="bg-gray-25 text-dark border-0 px-3 p-2 rounded inter-700 body-text2 me-2">
                          {projectDetails?.rooms[0].project_data?.property_name}
                        </Button>
                        <Button className="bg-gray-25 text-dark border-0 px-3 p-2 rounded inter-700 body-text2">
                          {
                            projectDetails?.property_data?.project_type_data
                              ?.title
                          }
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div
                    className="d-flex flex-column overflow-hidden border"
                    style={{ borderRadius: 12, height: 450 }}
                  >
                    <div
                      className="inter-600 p-3 pb-2"
                      style={{ flex: "0 0 auto" }}
                    >
                      Products Used in Image
                    </div>
                    {projectDetails?.products?.length > 0 ? (
                      <div
                        className="overflow-scroll p-3"
                        // style={{ height: 450 }}
                        style={{ flex: "1 1 auto" }}
                      >
                        {projectDetails?.products.map((product, index) => (
                          <ProductCard
                            key={index}
                            {...product}
                            image={product?.media[0]?.file}
                            productTypeImage={product?.product_category_data?.image}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="px-3">
                        <div
                          className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                          style={{ height: 160 }}
                        >
                          No products available
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <div>
                <Row>
                  {rooms?.map((room, index) => {
                    return (
                      <Col key={index} xs={6} md={4} lg={3} xl={2}>
                        <div
                          className="bg-gray-25 rounded overflow-hidden cursor-pointer mb-3"
                          onClick={() => {
                            setRoomData({
                              id: room.id,
                              projectId: room.project,
                              roomImage: room?.media[0]?.file,
                            });
                            setActiveSpaceIndex(index);
                            setShowSpaceView(true);
                          }}
                        >
                          <img
                            src={room.media[0]?.file}
                            className="img-fluid w-100 rounded-3"
                            style={{ maxHeight: 150, minHeight: 150 }}
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
                <SpacesSliderView
                  ativeRoomIndex={activeSpaceIndex}
                  setActiveRoomIndex={setActiveSpaceIndex}
                  show={showSpaceView}
                  setShow={setShowSpaceView}
                  roomData={roomData}
                />
                {projectDetails?.rooms?.length > roomsInRow && (
                  <div
                    className="geist text-primary fw-bold"
                    onClick={() => setShowRooms(!showAllRooms)}
                  >
                    View all{" "}
                    {showAllRooms ? (
                      <BiChevronUp size={24} />
                    ) : (
                      <BiChevronDown size={24} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Container>
          <Container className="geist my-4 my-md-5">
            <div className="h3 inter-600">
              Want these interiors for your home?
            </div>
            <div className="body-text2">
              At Interiokeys, you can choose from the 2 types of services
              provided. We recommend going Turnkey!
            </div>
            <div className="d-flex flex-column flex-lg-row my-4">
              <Col className="card p-3 border-primary">
                <div className="h4 inter-700 text-start mt-3">
                  Turnkey (Complete) Interior Solution
                </div>
                <div className="inter-400 text-start">
                  Complete hassle-free execution of your home interiors from
                  ideation to handover, while maintaining transparency every
                  step of the way.
                </div>

                <Button
                  className="body-title inter-600 text-white mt-4 p-2"
                  onClick={() => {
                    if (projectDetails?.id) {
                      console.log(projectDetails);
                      navigate(`/consultation#turnkey`, {
                        state: {
                          servicetype: "turnkey",
                          project: projectDetails,
                        },
                      });
                    } else {
                      notify("Project undefined, try again later", "error");
                    }
                  }}
                >
                  Book Free Consultation (Recommended)
                </Button>
              </Col>
              <Col
                lg={1}
                className="d-flex justify-content-center align-items-center py-4"
              >
                <div className="fw-bold">OR</div>
              </Col>
              <Col className="card p-3">
                <div className="h4 inter-700 text-start mt-3">
                  Project Drawings Only
                </div>
                <div className="inter-400 text-start mb-3">
                  Final construction drawings and architect support till release
                  of final drawings. Project execution not included.
                </div>
                <div className="d-flex flex-column flex-lg-row text-white mt-4 mt-auto">
                  <div className="mb-2 mb-lg-0 me-3 me-lg-0 w-100">
                    <Button
                      variant="info"
                      className="body-title w-95 inter-600 w-100 text-gray-900 p-2"
                      onClick={() => {
                        if (projectDetails?.id) {
                          console.log(projectDetails);
                          navigate(
                            `/consultation#customised&project=${slug}`,
                            {
                              state: {
                                servicetype: "customised",
                                project: projectDetails,
                              },
                            }
                          );
                        } else {
                          notify("Project undefined, try again later", "error");
                        }
                      }}
                    >
                      Book Free Consultation
                    </Button>
                  </div>
                  {large && <div className="p-2" />}
                  <div className={`mb-2 mb-lg-0 w-100`}>
                    <Button className="body-title w-95 inter-600 w-100 bg-gray p-2">
                      <MdOutlineFileDownload /> Sample Estimate and Plans
                    </Button>
                  </div>
                </div>
              </Col>
            </div>
          </Container>
        </div>
      )}
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={projectDetails?.id}
        image={projectDetails?.user_data?.profile_picture}
        title={projectDetails?.project_name}
        urlPath={slug !== "undefined" ? `/projects/${slug}` : null}
      />
    </div>
  );
};

export default ProjectDetails;
