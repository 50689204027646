import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa6";

const RoomList = ({ room, index, rooms, setRooms, setSelectedRoomType }) => {
  const [quantity, setQuantity] = useState(room.quantity);

  const handleUpdateRoomCount = () => {
    let updatedRooms = rooms;
    updatedRooms[index].quantity = quantity;
    setRooms(updatedRooms);
    let selectedRooms = updatedRooms
      .filter((item) => item.quantity > 0);
    setSelectedRoomType(
      selectedRooms?.map((item) => ({ [item.label]: item.quantity }))
    );
  }

  useEffect(() => {
    handleUpdateRoomCount()
  }, [quantity])

  return (
    <div key={index} className={`body-text2 inter-400 d-flex justify-content-between user-select-none ${index === 0 ? "" : "mt-2"}`}>
      <div>{room.label}</div>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className={`me-2 d-flex justify-content-center align-items-center ${quantity === 0 ? "" : "cursor-pointer"}`}
          style={{ width: "20px", height: "20px", borderRadius: 10, background: quantity === 0 ? "#FFFFFF" : "#F8F9FA" }}
          onClick={() => {
            if (quantity > 0) {
              setQuantity(quantity - 1)
            }
          }}
        >
          <FaMinus size={12} color={quantity === 0 ? "#BDC1C6" : "#1A1C1F"} />
        </div>
        <div>{quantity}</div>
        <div
          className="ms-2 d-flex justify-content-center align-items-center cursor-pointer"
          style={{ width: "20px", height: "20px", borderRadius: 10, background: "#F8F9FA" }}
          onClick={() => setQuantity(quantity + 1)}
        >
          <FaPlus size={12} />
        </div>
      </div>
    </div>
  )
}

const InteriorRequirement = ({
  setRequirement,
  floorPlan,
  setFloorPlan,
  setSelectedRoomType,
}) => {

  const [selectedOption, setSelectedOption] = useState("");
  const [rooms, setRooms] = useState([
    { id: 1, label: "Bedroom", value: "bedroom", quantity: 0 },
    { id: 2, label: "Bathroom", value: "bathroom", quantity: 0 },
    { id: 3, label: "Kitchen", value: "kitchen", quantity: 0 },
    { id: 4, label: "Dining Room", value: "diningroom", quantity: 0 },
    { id: 5, label: "Living Room", value: "livingroom", quantity: 0 },
  ]);
  const floorPlans = [
    { id: 1, title: "2 BHK", slug: "2BHK" },
    { id: 2, title: "2.5 BHK", slug: "2.5BHK" },
    { id: 3, title: "3 BHK", slug: "3BHK" },
    { id: 4, title: "3.5 BHK", slug: "3.5BHK" },
    { id: 5, title: "4 BHK", slug: "4BHK" },
    { id: 6, title: "5 BHK", slug: "5BHK" },
  ]

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setRequirement(event.target.value);
  };

  return (
    <div>
      <div className="mb-3">
        <div className="body-text1 inter-500 text-start mt-3 mb-1">
          Your Requirement
        </div>
        <div className="body-text2 inter-500 text-start mb-1">
          Service
        </div>
        <div className="d-flex justify-content-between body-text2">
          <div
            className={`text-gray-900 bg-white px-2 py-1 me-2 inter-500 w-100 border ${selectedOption === "complete_home" ? "border-gray-400" : "border-gray-200"}`}
            style={{ borderRadius: 3 }}
          >
            {/* <Form.Check
              className="d-flex align-items-center"
              type="radio"
              label={<span className={`ms-1 ${selectedOption === "complete_home" ? "text-gray-700" : "text-gray-200"}`}>Turnkey (Complete) Home Interiors</span>}
              name="requirement"
              value="complete_home"
              onClick={handleOptionChange}
            /> */}
            <label onClick={handleOptionChange} className="d-flex align-items-center cursor-pointer">
              <Form.Check
                type="radio"
                name="requirement"
                value="complete_home"
                className="me-2"
                checked={selectedOption === "complete_home"}
                readOnly // Make it read-only to allow label click to work without direct Form.Check event
              />
              <span className={`ms-1 ${selectedOption === "complete_home" ? "text-gray-700" : "text-gray-200"}`}>
                Turnkey (Complete) Home Interiors
              </span>
            </label>
          </div>
          <div
            className={`text-gray-900 bg-white px-2 py-1 inter-500 w-100 border ${selectedOption === "selected_rooms" ? "border-gray-400" : "border-gray-200"}`}
            style={{ borderRadius: 3 }}
          >
            {/* <Form.Check
              className="d-flex align-items-center"
              type="radio"
              label={<span className={`ms-1 ${selectedOption === "selected_rooms" ? "text-gray-700" : "text-gray-200"}`}>Selected Spaces Only</span>}
              name="requirement"
              value="selected_rooms"
              onClick={handleOptionChange}
            /> */}
            <label onClick={handleOptionChange} className="d-flex align-items-center cursor-pointer">
              <Form.Check
                type="radio"
                name="requirement"
                value="selected_rooms"
                className="me-2"
                checked={selectedOption === "selected_rooms"}
                readOnly // Keeps the radio from being modified directly
              />
              <span className={`ms-1 ${selectedOption === "selected_rooms" ? "text-gray-700" : "text-gray-200"}`}>
                Selected Spaces Only
              </span>
            </label>
          </div>
        </div>
        {selectedOption === "selected_rooms" && (
          <div className="bg-white mt-2" style={{ border: "1px solid #DADCE0", borderRadius: 6, padding: 12 }}>
            {rooms.map((room, index) => (
              <RoomList room={room} index={index} rooms={rooms} setRooms={setRooms} setSelectedRoomType={setSelectedRoomType} />
            ))}
          </div>
        )}
      </div>
      <div>
        <div className="body-text2 inter-500 text-start mb-1">
          Layout Plan
        </div>
        <Row className="row-cols-3 px-2">
          {floorPlans.map((plan) => {
            return (
              <Col className="mb-2 px-1">
                <Button
                  className={`body-text2 inter-500 w-100 btn-bg-custom ${floorPlan === plan.slug
                    ? "border-gray-600 text-gray-700 bg-gray-50"
                    : "border-gray-300 text-gray-700 bg-white"
                    }`}
                  style={{ borderRadius: 3, minHeight: 36 }}
                  onClick={() => setFloorPlan(plan.slug)}
                >
                  {plan.title}
                </Button>
              </Col>
            )
          })}
        </Row>
      </div>
    </div>
  );
};

export default InteriorRequirement;
