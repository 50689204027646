import { useEffect, useState } from "react";
import SpaceCard from "components/Cards/SpaceCard";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import ProjectCategoryCard from "components/Cards/ProjectCategoryCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SpacesSliderView from "./SpacesSliderView";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import * as authActions from "reduxFiles/actions/authActions";
import Pagination from "components/Pagination";
import { space_switch_card } from "assets";
import { FiRefreshCcw } from "react-icons/fi";
import { CgSortAz } from "react-icons/cg";
import { IoClose } from "react-icons/io5";

const SpacesList = () => {
  const navigate = useNavigate();
  // const { slug } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categorySlug = queryParams.get("room-caterogry");
  const roomTypeSlug = queryParams.get("room-type");

  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const xxlarge = useMediaQuery({ query: "(min-width: 1400px)" });

  let params = new URLSearchParams(document.location.search);
  let roomType = params.get("room_type");
  let property = params.get("property");
  let bhk = params.get("bhk");
  let sortBy = params.get("sort_by");

  const [switchPage, setSwitchPage] = useState(false);
  const [roomData, setRoomData] = useState(null);
  const [properties, setProperties] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSpaceView, setShowSpaceView] = useState(false);
  const [activeSpaceIndex, setActiveSpaceIndex] = useState(0);

  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);

  const layouts = [
    { id: 1, label: "2 BHK", value: "2BHK" },
    { id: 2, label: "2.5 BHK", value: "2.5BHK" },
    { id: 3, label: "3 BHK", value: "3BHK" },
    { id: 4, label: "3.5 BHK", value: "3.5BHK" },
    { id: 5, label: "4 BHK", value: "4BHK" },
  ]

  const fetchProperties = async () => {
    try {
      const response = await authActions.fetchAllProperties();
      if (response.data) {
        setProperties(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSpaces = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRooms(
      page,
      roomType,
      property,
      bhk,
      sortBy
    );
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onChangeFilters = (value, type) => {
    let filters = "?"
    // Project Type
    if (type === "room_type") {
      filters += `room_type=${value}`;
    } else {
      filters += `room_type=${roomType}`;
    }

    // Property
    if (type === "property") {
      filters += `&property=${value}`;
    } else {
      filters += `&property=${property}`;
    }

    // Layout
    if (type === "bhk") {
      filters += `&bhk=${value}`;
    } else {
      filters += `&bhk=${bhk}`;
    }

    // Sort By
    if (type === "sort_by") {
      filters += `&sort_by=${value}`;
    } else {
      filters += `&sort_by=${sortBy}`;
    }

    navigate(`/spaces${filters}`);
  }

  const fetchRoomsByCateogrySlug = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRoomsByCategorySlug(
      roomTypeSlug,
      page
    );
    console.log(response.data);
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomsByRoomTypeSlug = async (page) => {
    setLoading(true);
    const response = await authActions.fetchRoomsByRoomTypeSlug(
      roomTypeSlug,
      page
    );
    console.log(response.data);
    try {
      if (response.data) {
        setRooms(response.data?.results);
        setTotalPages(Math.ceil(response.data.count / 12));
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRoomTypes = async (page) => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRoomTypes();
      if (response.data) {
        setRoomTypes(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchSpaces();
  }, [roomType, property, bhk, sortBy]);

  useEffect(() => {
    if (categorySlug) {
      fetchRoomsByCateogrySlug(currentPage);
    } else if (roomTypeSlug) {
      fetchRoomsByRoomTypeSlug(currentPage);
    } else {
      fetchSpaces(currentPage);
    }
  }, [currentPage, categorySlug, roomTypeSlug]);

  useEffect(() => {
    fetchProperties();
    fetchRoomTypes();
  }, []);

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-prev-arrow`}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowLeft size={25} color="black" /> */}
      </div>
    );
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={`${className} custom-next-arrow`}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        {/* <MdKeyboardArrowRight size={25} color="black" /> */}
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: xxlarge
      ? 8.5
      : xlarge
        ? 7
        : large
          ? 6
          : medium
            ? 4
            : small
              ? 2.5
              : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        <div className="d-flex">
          <div className="pe-3">
            <Card
              className={`border-0 p-0 text-start bg-brand-500 text-white shadow-none cursor-pointer`}
              style={{ width: 128, borderRadius: 5 }}
              onMouseEnter={() => setSwitchPage(true)}
              onMouseLeave={() => setSwitchPage(false)}
              onClick={() => navigate("/projects")}
            >
              {switchPage && (
                <div className="position-absolute w-100 h-100 top-0 start-0 body-text2 text-white d-flex flex-column align-items-center justify-content-center"
                  style={{ backgroundColor: "rgba(0, 0, 0, 0.3)", borderRadius: 6 }}
                />
              )}
              <Card.Img
                className="img-fluid"
                vadivriant="top"
                src={space_switch_card}
                style={{ width: 128, maxHeight: "90px", minHeight: "100px", borderRadius: "" }}
              />
              <Card.Body className="px-0 py-2 text-center ">
                <Card.Title className="body-text2 m-0 d-flex justify-content-center align-items-center">
                  <span className="me-2">{switchPage ? "Projects" : "Spaces"}</span>
                  <FiRefreshCcw size={18} />
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
          {roomTypes?.length > 0 && (
            <div
              className={`${large ? "w-90" : medium ? "w-80" : small ? "w-80" : "w-50"
                } ps-3`}
            >
              <Slider {...settings}>
                {roomTypes?.map((room, index) => (
                  <ProjectCategoryCard
                    key={index}
                    {...room}
                    selected={roomType}
                    onClick={() => {
                      // navigate(`/spaces?room-type=${room.slug}`)
                      onChangeFilters(room.slug, "room_type");
                    }}
                  />
                ))}
              </Slider>
            </div>
          )}
        </div>
        <div className="body-title my-4">
          <span className="inter-600">{rooms?.length} spaces</span> found in your city
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            {(property && property !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {properties.filter((prop) => prop.slug === property).length > 0
                    ?
                    properties.filter((prop) => prop.slug === property)[0].property_name
                    :
                    property
                  }
                </span>
                <div className="cursor-pointer" onClick={() => onChangeFilters(null, "property")}>
                  <IoClose size={20} />
                </div>
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={(e) => onChangeFilters(e.target.value, "property")}
                value={property && property !== "null" ? property : ""}
              >
                <option value="" disabled>
                  Property Name
                </option>
                {properties.map((property) => {
                  return (
                    <option value={property.slug}>{property.property_name}</option>
                  )
                })}
              </Form.Select>
            }
            {(bhk && bhk !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {layouts.filter((lay) => lay.value === bhk)[0].label}
                </span>
                <div className="cursor-pointer" onClick={() => onChangeFilters(null, "bhk")}>
                  <IoClose size={20} />
                </div>
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={(e) => onChangeFilters(e.target.value, "bhk")}
                value={bhk && bhk !== "null" ? bhk : ""}
              >
                <option value="" disabled>
                  Floor Layout
                </option>
                {layouts.map((layout) => {
                  return (
                    <option value={layout.value}>{layout.label}</option>
                  )
                })}
              </Form.Select>
            }
            {/* <Form.Select
              className="bg-transparent text-gray-700 me-2 cursor-pointer"
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              // onChange={(e) => setTheme(e.target.value)}
              value=""
            >
              <option value="" disabled>
                Interior Theme
              </option>
              <option value="modern">Modern</option>
              <option value="contemporary">Contemporary</option>
              <option value="traditional">Traditional</option>
              <option value="neo_classical">Neo-Classical</option>
              <option value="eclectic">Eclectic</option>
              <option value="tropical">Tropical</option>
              <option value="mediterranean">Mediterranean</option>
            </Form.Select> */}
            {/* <Form.Select
              className="bg-transparent text-gray-700 me-2 cursor-pointer"
              style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              // onChange={(e) => setTheme(e.target.value)}
              value=""
            >
              <option value="" disabled>
                Project Budget
              </option>
              <option value="modern">{`<500`}</option>
              <option value="contemporary">{`>500 & <10000`}</option>
              <option value="traditional">{`>10000 & <50000`}</option>
            </Form.Select> */}
          </div>
          <div>
            <div className="custom-select-wrapper">
              <Form.Select
                className="bg-transparent text-gray-700 cursor-pointer custom-select"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                value={sortBy && sortBy !== "null" ? sortBy : ""}
              >
                <option value="" disabled>Sort By</option>
                <option value="created_at">Featured (New to Old)</option>
                <option value="-created_at">Featured (Old to New)</option>
              </Form.Select>
              <CgSortAz size={24} className="custom-dropdown-icon" />
            </div>
          </div>
        </div>
        <div className="mb-3">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : rooms?.length > 0 ? (
            <div className="d-flex flex-column align-items-center" style={{ minHeight: "80vh" }}>
              <Row className="row-cols-3 w-100">
                {rooms?.map((room, index) => (
                  <Col
                    key={index}
                    className={`d-flex flex-column py-2 mb-2 justify-content-start ${index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : ""}`}
                  >
                    <SpaceCard
                      {...room?.room_data}
                      id={room.id}
                      projectId={room.project}
                      project={room.project_data}
                      image={room.media[0]?.file}
                      title={`${room.room_type_data?.title}/${room.project_data?.property_name}`}
                      // type={`${room.project_data.bhk}, Block ${room.project_data.block}, ${room.project_data.project_area}`}
                      type={`${room.project_data.bhk}, ${room.project_data.project_area}`}
                      setShow={setShowSpaceView}
                      setRoomData={setRoomData}
                      onClick={() => {
                        console.log(room);
                        setRoomData({
                          id: room.id,
                          projectId: room.project,
                          roomImage: room?.media[0]?.file,
                        });
                        setShowSpaceView(true);
                      }}
                    />
                  </Col>
                ))}
              </Row>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
                onPageChange={handlePageChange}
              />
              <SpacesSliderView
                ativeRoomIndex={activeSpaceIndex}
                setActiveRoomIndex={setActiveSpaceIndex}
                show={showSpaceView}
                setShow={setShowSpaceView}
                roomData={roomData}
              />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
              style={{ height: '60vh' }}
            >
              No spaces available
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default SpacesList;
