import { useState } from "react";
import { Card } from "react-bootstrap";
import { GoStarFill } from "react-icons/go";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

const ArchitectsForDisplayCard = ({ recommendedArchitect }) => {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const large = useMediaQuery({ query: "(min-width: 992px)" });

    return (
        <Card
            key={recommendedArchitect?.id}
            className="cursor-pointer py-0 px-0 mx-2 border-0 rounded overflow-hidden"
            style={{ boxShadow: "none" }}
            onClick={() => navigate(`/professionals/architect/${recommendedArchitect?.slug}`)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Card.Img
                className="img-fluid"
                variant="top"
                src={recommendedArchitect?.user_data?.profile_picture}
                alt={recommendedArchitect?.company_name}
                style={{
                    objectFit: "cover",
                    borderTop: "1px solid #E8EAED",
                    borderLeft: "1px solid #E8EAED",
                    borderRight: "1px solid #E8EAED",
                    borderRadius: "6px 6px 0px 0px"
                }}
            />
            <Card.Body
                className="p-2 d-flex flex-column align-items-start border"
                style={{
                    border: "1px solid #E8EAED",
                    borderRadius: "0px 0px 6px 6px"
                }}
            >
                <div
                    className={`body-title inter-500 mb-1 ${hover ? "text-primary" : ""}`}
                    style={{ fontSize: "16px" }}
                >
                    {recommendedArchitect?.company_name}
                </div>
                <div className="body-text2 inter-400 text-gray-600 d-flex justify-content-start align-items-center">
                    <span className="me-1">Ratings: {recommendedArchitect?.rating}/5</span>
                    <GoStarFill size={18} color="#C53D3D" />
                </div>
                <div className="body-text2 inter-400 text-gray-600 d-flex justify-content-start align-items-center">
                    Completed Projects: {recommendedArchitect?.project_count}
                </div>
            </Card.Body>
        </Card>
    );
};

export default ArchitectsForDisplayCard;
