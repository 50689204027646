import ProductListCard from "components/Cards/ProductListCard";
import Pagination from "components/Pagination";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { CgSortAz } from "react-icons/cg";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";

const ProductLibrary = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";

  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  let searchparams = new URLSearchParams(document.location.search);
  let productCategory = searchparams.get("product_category");
  let productType = searchparams.get("productType");
  let sortBy = searchparams.get("sort_by");

  const [productCategories, setProductCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsPagewise();
      if (response.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchProductsByBrand = async () => {
    setLoading(true);
    try {
      const response = await authActions.fetchProductsByBrandUser(user?.id);
      if (response.data) {
        setProducts(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (param1 === "architect") {
      fetchProducts();
    } else if (param1 === "brand") {
      fetchProductsByBrand();
    }
  }, [param1, user]);

  return (
    <Container fluid>
      <Container className="mt-5 pt-5">
        <div className="body-title my-4">
          <span className="inter-600">Products Available({products?.length})</span>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            {(productCategory && productCategory !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {productCategory}
                </span>
                {/* <div className="cursor-pointer" onClick={() => onChangeFilters(null, "productCategory")}>
                  <IoClose size={20} />
                </div> */}
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "productCategory")}
                value={productCategory && productCategory !== "null" ? productCategory : ""}
              >
                <option value="" disabled>
                  Product Category
                </option>
                {productCategories.map((productCat, index) => {
                  return (
                    <option key={index + 1} value={productCat?.slug}>{productCat?.property_name}</option>
                  )
                })}
              </Form.Select>
            }
            {(productType && productType !== "null") ?
              <div
                className="d-flex justify-content-center align-items-center flex-grow bg-transparent text-gray-700 px-2 me-2"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
              >
                <span className="me-2">
                  {productType}
                </span>
                {/* <div className="cursor-pointer" onClick={() => onChangeFilters(null, "productCategory")}>
                  <IoClose size={20} />
                </div> */}
              </div>
              :
              <Form.Select
                className="bg-transparent text-gray-700 me-2 cursor-pointer"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "productType")}
                value={productType && productType !== "null" ? productType : ""}
              >
                <option value="" disabled>
                  Product Type
                </option>
                {productTypes.map((prodType, index) => {
                  return (
                    <option key={index + 1} value={prodType.value}>{prodType.label}</option>
                  )
                })}
              </Form.Select>
            }
          </div>
          <div>
            <div className="custom-select-wrapper">
              <Form.Select
                className="bg-transparent text-gray-700 cursor-pointer custom-select"
                style={{ border: "1px solid #BDC1C6", borderRadius: 3 }}
                // onChange={(e) => onChangeFilters(e.target.value, "sort_by")}
                value={sortBy && sortBy !== "null" ? sortBy : ""}
              >
                <option value="" disabled>Sort By</option>
                <option value="created_at">Featured (New to Old)</option>
                <option value="-created_at">Featured (Old to New)</option>
              </Form.Select>
              <CgSortAz size={24} className="custom-dropdown-icon" />
            </div>
          </div>
        </div>
        <div className="mb-3">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <Spinner />
            </div>
          ) : products?.length > 0 ? (
            <div className="d-flex flex-column align-items-center" style={{ minHeight: "80vh" }}>
              <Row className="w-100">
                {products.map((product, index) => (
                  <Col
                    key={index}
                    lg={4}
                    md={6}
                    sm={12}
                    className={`d-flex flex-column py-2 mb-2 justify-content-start ${large ? (index % 3 === 0 ? "ps-0" : index % 3 === 2 ? "pe-0" : "") : medium ? (index % 2 === 0 ? "ps-0" : "pe-0") : "p-0"}`}
                  >
                    <ProductListCard
                      {...product}
                      image={product.media[0]?.file ?? product.product_category_data?.image}
                      product_type={product.product_category_data?.title}
                      className={`cursor-pointer`}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center bg-gray-25 rounded w-100"
              style={{ height: '60vh' }}
            >
              No products available
            </div>
          )}
        </div>
      </Container>
    </Container>
  );
};

export default ProductLibrary;
