import React, { useRef, useState } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";
import { LuEyeOff, LuEye } from "react-icons/lu";
import { MdOutlinePhonelinkLock } from "react-icons/md";

const ForgotPassword = ({ email, setFormType }) => {
  const form = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(form.current);

    if (formData.get("password") === formData.get("confirm_password")) {
      const response = await authActions.reset_password({
        password: formData.get("password"),
        email: email,
        otp: formData.get("otp"),
      });
      if (response) {
        form.current.reset();
        switch (response.status) {
          case "LINK_EXPIRED":
            notify(response.msg, "error");
            break;
          case "INCORRECT_OTP":
            notify(response.msg, "error");
            break;
          case "DOES_NOT_EXIST":
            notify(response.msg, "error");
            setFormType("login");
            break;
          case "RESET_SUCCESSFUL":
            notify(response.msg, "success");
            setFormType("login");
            break;
          default:
            notify("Error while sending OTP, Try again later", "error");
        }
      }
    } else {
      notify("Passwords Do Not Match.");
    }
    setIsSubmitting(false);
  };

  return (
    <Form
      className="text-start w-100"
      ref={form}
      onSubmit={(e) => handleResetPassword(e)}
    >
      <Form.Group className="mb-3">
        <Form.Label className="mb-1 body-text2 inter-600">
          OTP<sup style={{ color: "red" }}>*</sup>
        </Form.Label>
        <InputGroup>
          <InputGroup.Text
            style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
          >
            <MdOutlinePhonelinkLock size={20} color="#1A1C1F" />
          </InputGroup.Text>
          <Form.Control
            className="border-start-0 p-2"
            type="password"
            name="otp"
            required
            placeholder="****"
            style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1 body-text2 inter-600">
          Password<sup style={{ color: "red" }}>*</sup>
        </Form.Label>
        <InputGroup>
          <InputGroup.Text
            className="cursor-pointer"
            style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
          </InputGroup.Text>
          <Form.Control
            className="border-start-0 p-2"
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="**********"
            style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
            pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
            title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1 body-text2 inter-600">
          Confirm Password<sup style={{ color: "red" }}>*</sup>
        </Form.Label>
        <InputGroup>
          <InputGroup.Text
            className="cursor-pointer"
            style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
          </InputGroup.Text>
          <Form.Control
            className="border-start-0 p-2"
            type={showConfirmPassword ? "text" : "password"}
            name="confirm_password"
            placeholder="**********"
            style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
            pattern="^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$"
            title="Password must contain at least 1 uppercase letter, 1 digit, and be at least 8 characters long"
          />
        </InputGroup>
      </Form.Group>

      <Button
        variant="primary"
        className="border-0 w-100 custom-button mt-2 mb-3 py-2"
        type="submit"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <span>
            <Spinner size="sm" className="me-2" />
            Processing ...
          </span>
        ) : (
          <span>Reset Password</span>
        )}
      </Button>
    </Form>
  );
};

export default ForgotPassword;
