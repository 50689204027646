import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { useSelector } from "react-redux";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const ProductListCard = ({
  id,
  title,
  product_type,
  brand_name,
  cost,
  description,
  image,
  imgHeight = "300px",
  onClick,
  className,
  style,
  showActions = true,
}) => {
  const user = useSelector((state) => state.auth.user);

  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const checkProductIfSaved = async () => {
    try {
      const response = await authActions.checkProductIfSaved(user.id, id);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveProduct = async () => {
    try {
      const response = await authActions.saveProduct(user.id, id);
      if (response.data) {
        setIsLiked(true);
        notify("Product saved!", "success");
      } else {
        notify("Failed to save product, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveProduct = async () => {
    try {
      const response = await authActions.unsaveProduct(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Product unsaved!", "success");
      } else {
        notify("Failed to unsave product, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkProductIfSaved();
  }, []);

  return (
    <>
      <Card
        className={`w-100 py-0 px-0 border rounded shadow-none overflow-hidden position-relative ${hover ? "border-gray-300" : "border-gray-25"} ${className}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={onClick}
      >
        <Card.Img
          className="img-fluid bg-gray-50"
          variant="top"
          src={image}
          style={{
            maxHeight: imgHeight,
            minHeight: imgHeight,
            objectFit: "cover",
          }}
        />
        {showActions && <div
          className="d-flex position-absolute"
          style={{ zIndex: 999, right: 15, top: 10 }}
        >
          <Button
            className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""
              }`}
            onClick={(e) => {
              e.stopPropagation();
              isLiked ? handleUnsaveProduct() : handleSaveProduct();
            }}
          >
            <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : ""} />
          </Button>
        </div>}
        <Card.Body className="p-1 d-flex flex-column align-items-start">
          <Card.Title className={`inter-700 body-text1 mb-0 ${hover ? "text-primary-500" : "text-gray-900"}`}>
            {title}
          </Card.Title>
          <div className="body-text2 inter-400 d-flex flex-column align-items-start">
            <span className="ms-0">{brand_name}</span>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductListCard;
