import React from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from "react-router-dom";
import axios from "axios";

import { Navbar, Footer, ScrollToTop } from "components";

import Home from "pages/Home/Home";
import PartnerSignup from "pages/SignUp/PartnerSignup";
import Signup from "pages/SignUp/Signup";

import Consultation from "pages/Consultaion/Consultation";

import ProjectList from "pages/Projects/ProjectList";
import SpacesList from "pages/Projects/SpacesList";
import ProjectDetails from "pages/Projects/ProjectDetails";
import BrandsList from "pages/Professionals/BrandsList";
import BrandDetails from "pages/Professionals/BrandDetails";
import ProfessionalDetails from "pages/Professionals/ProfessionalDetails";
import WhyChooseUs from "pages/WhyChooseUs/WhyChooseUs";

// Customer Logged in Pages
import LoginHomepage from "pages/Home/LoginHomepage";
import UserProfile from "pages/User/UserProfile";
import ProjectTracker from "pages/User/ProjectTracker";
import Saved from "pages/User/Saved";

// Builder Logged in Pages
import BuilderProperties from "pages/Builder/BuilderProperties";
import AddProperty from "pages/Builder/AddProperty";
import EditProperty from "pages/Builder/EditProperty";
import InspirationsCreated from "pages/Builder/InspirationsCreated";
import BuilderPersonalProfile from "pages/Builder/Account/BuilderPersonalProfile";
import BuilderPublicProfile from "pages/Builder/Account/PublicProfile";

// Architect Logged in Pages
import DesignerLanding from "pages/Designer/DesignerLanding";
import AddProject from "pages/ProfessionalSpecific/AddProject";
import ProductLibrary from "pages/ProfessionalSpecific/ProductLibrary";
import ArchitectPublicProfile from "pages/ProfessionalSpecific/ArchitectPublicProfile";
import ProfessionalSettings from "pages/ProfessionalSpecific/ProfessionalSettings";

import BuilderDetails from "pages/Professionals/BuilderDetails";
import ArchitectsList from "pages/Professionals/ArchitectsList";
import BuildersList from "pages/Professionals/BuildersList";
import ProfessionalsList from "pages/Professionals/ProfessionalsList";
import AddProduct from "pages/ProfessionalSpecific/AddProducts";
import ProfessionalHome from "pages/Home/ProfessionalHome";

import PageNotFound from "pages/Errors/PageNotFound";
import DesignerProfile from "pages/Designer/Account/DesignerProfile";


const Routers = () => {
  const token = useSelector((state) => state.auth.token);
  // const user = useSelector((state) => state.authReducer.user);
  // const isAuthenticated = useSelector(
  //   (state) => state.authReducer.isAuthenticated
  // );
  // const isAdmin = useSelector((state) => state?.authReducer?.user?.is_admin);
  // const isSuperuser = useSelector((state) => state?.authReducer?.user?.is_superuser);
  // const deviceWidth = useCurrentWidth();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  console.log("isAuthenticated, user:", isAuthenticated, user);

  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  if (token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  return (
    <Router>
      <ScrollToTop />
      <Container fluid className="px-0 inter">
        <Navbar />
        <Routes>
          {isAuthenticated && user?.user_type === "customer" ? (
            <Route path="" element={<LoginHomepage />} />
          ) :
            user?.user_type === "builder" ? (
              <Route>
                <Route path="" element={<Navigate to="/builder/properties" />} />
                <Route path="builder" element={<Outlet />}>
                  <Route path="inspirations" element={<InspirationsCreated />} >
                    <Route path=":propertySlug" element={<InspirationsCreated />} />
                  </Route>
                  <Route path="properties" element={<BuilderProperties />} >
                    <Route path=":propertyId" element={<EditProperty />} />
                  </Route>
                  <Route path="addproperty" element={<AddProperty />} />
                  <Route path="account" element={<Outlet />}>
                    <Route path="profile" element={<BuilderPersonalProfile />} />
                    {/* Comment this - start */}
                    <Route path="settings" element={<BuilderPublicProfile />} />
                    {/* Comment this - end */}
                  </Route>
                </Route>
              </Route>
            ) : user?.user_type === "architect" ? (
              <Route>
                <Route path="" element={<Navigate to="/architect/projects" />} />
                <Route path="architect" element={<Outlet />}>
                  <Route path="projects" element={<DesignerLanding />} />
                  <Route path="addproject" element={<AddProject />}>
                    <Route path=":propertyId" element={<AddProject />}>
                      <Route path=":status" element={<AddProject />} />
                    </Route>
                  </Route>
                  <Route path="product-library" element={<ProductLibrary />} />
                  <Route path="account" element={<Outlet />}>
                    {/* Comment this - start */}
                    {/* <Route path="settings" element={<ArchitectPublicProfile />} /> */}
                    <Route path="settings" element={<ProfessionalSettings />} />
                    {/* Comment this - end */}
                    <Route path="profile" element={<DesignerProfile />} />
                  </Route>
                </Route>
              </Route>
            ) : user?.user_type === "brand" ? (
              <Route>
                <Route path="" element={<Navigate to="/brand/projects" />} />
                <Route path="brand" element={<Outlet />}>
                  <Route path="projects" element={<DesignerLanding />} />
                  <Route path="addproject" element={<AddProject />}>
                    <Route path=":propertyId" element={<AddProject />}>
                      <Route path=":status" element={<AddProject />} />
                    </Route>
                  </Route>
                  <Route path="addproduct" element={<AddProduct />} />
                  <Route path="product-library" element={<ProductLibrary />} />
                  <Route path="account" element={<Outlet />}>
                    {/* Comment this - start */}
                    <Route
                      path="settings"
                      element={<ProfessionalSettings />}
                    />
                    {/* Comment this - end */}
                    <Route path="profile" element={<ArchitectPublicProfile />} />
                  </Route>
                </Route>
              </Route>
            ) : (
              <Route path="" element={<Home />} />
            )}
          {isAuthenticated && (
            <Route path="user" element={<Outlet />}>
              <Route path="profile" element={<UserProfile />} />
              <Route path="saved" element={<Saved />} />
              <Route path="projecttracker" element={<ProjectTracker />} />
            </Route>
          )}
          <Route path="partner" element={<ProfessionalHome />} />
          <Route path="partner-signup" element={<PartnerSignup />} />
          <Route path="signup" element={<Signup />} />
          <Route path="consultation" element={<Consultation />}>
            <Route path=":refType/:refId" element={<Consultation />} />
          </Route>
          <Route path="properties" element={<ProjectList />}>
            <Route path=":propertyId" element={<ProjectDetails />} />
          </Route>
          <Route path="projects" element={<ProjectList />}>
            <Route path=":slug" element={<ProjectDetails />} />
          </Route>
          <Route path="spaces" element={<SpacesList />} />
          <Route path="brands" element={<BrandsList />}>
            <Route path=":slug" element={<BrandDetails />} />
          </Route>
          <Route path="professionals" element={<Outlet />}>
            {/* <Route path="builders" element={<BuildersList />} /> */}
            <Route path="builders" element={<BuildersList />}>
              <Route path=":detailedSlug" element={<BuilderDetails />} />
            </Route>
            <Route path="architects" element={<ArchitectsList />}>
              <Route path=":detailedSlug" element={<ProfessionalDetails />} />
            </Route>
            <Route path=":slug" element={<ProfessionalsList />}>
              <Route path=":detailedSlug" element={<ProfessionalDetails />} />
            </Route>
          </Route>
          {/* <Route path="builders" element={<Outlet />}>
            <Route path=":builderId" element={<BuilderDetails />} />
          </Route> */}
          <Route path="/why-choose-us" element={<WhyChooseUs />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
};

export default Routers;
