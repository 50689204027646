import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import Slider from "react-slick";

import Banners from "./Banners";
import HeroBanner from "./HeroBanner";
import ServiceCard from "./ServiceCard";
import ProjectCard from "components/Cards/ProjectCard";
import TestimonialCard from "components/Cards/TestimonialCard";
import VerticalSlider from "./VerticalSlider";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import BrandOnInspirations from "./BrandOnInspirations";

import { ArchitectsForDisplay, HowItWorks } from "components";
import { Breakpoint } from "react-socks";

const Home = () => {
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  // const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });
  const navigate = useNavigate();
  const targetRef = useRef(null);

  const [brands, setBrands] = useState(null);
  const [recommendedProjects, setRecommendedProjects] = useState([]);
  const [recommendedArchitects, setRecommendedArchitects] = useState([]);
  const [clientTestimonials, setClientTestimonials] = useState(null);
  const [architectTestimonials, setArchitectTestimonials] = useState(null);
  const [builderTestimonials, setBuilderTestimonials] = useState(null);
  const [brandTestimonials, setBrandTestimonials] = useState(null);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateScreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", updateScreenHeight);
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  const card_info = [
    {
      id: 1,
      title: "Real Inspirations",
      description: "Our designs are made for existing and upcoming properties/ builders. Ready to execute!"
    },
    {
      id: 2,
      title: "No intermediaries",
      description: "Interiokeys maintains a direct connection between itself, the craftsmen, manufacturers and the end consumers."
    },
    {
      id: 3,
      title: "Cost Reduction by 20-30%",
      description: "Ready-made designs and multiple brands working directly to reduce interior costs."
    }
  ]

  const fetchBrands = async () => {
    try {
      const response = await authActions.fetchRecommendedBrands();
      if (response.data) {
        setBrands(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedProjects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedProjects();
      if (response.data) {
        setRecommendedProjects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchRecommendedArchitects = async () => {
    // setLoading(true);
    try {
      const response = await authActions.fetchRecommendedArchitects();
      if (response.data) {
        setRecommendedArchitects(response.data?.results);
      }
    } catch (error) {
      console.log(error);
    }
    // setLoading(false);
  };

  const fetchTestimonialsWithType = async () => {
    try {
      const response = await authActions.fetchTestimonialsWithType();
      if (response.data) {
        setClientTestimonials(response.data?.client_testimonials);
        setArchitectTestimonials(response.data?.architect_testimonials);
        setBuilderTestimonials(response.data?.builder_testimonials);
        setBrandTestimonials(response.data?.brand_testimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchRecommendedProjects();
    fetchRecommendedArchitects();
    fetchTestimonialsWithType();
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-next-arrow"}
        style={{ ...style, display: "flex", right: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowRight size={35} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " custom-prev-arrow"}
        style={{ ...style, display: "flex", left: -15, zIndex: 1 }}
        onClick={onClick}
      >
        <MdKeyboardArrowLeft size={35} />
      </div>
    );
  }

  const sliderSettings = {
    customPaging: function (i) {
      return (
        <div className="custom-dot mt-3" />
      );
    },
    dotsClass: "slick-dots",
    dots: small ? false : true,
    arrows: false,
    infinite: true,
    autoplay: large ? false : true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: large ? 3 : small ? 2 : 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container fluid className="mt-5 px-0">
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center text-center"
        style={{ minHeight: "100vh" }}
      >
        <Container className={`p-0 ${screenHeight <= 800 ? "mt-5" : ""}`}>
          <div className="h1 px-lg-5 inter-600">
            {/* “Collection of <span className="inter-700">real interior designs</span> for new properties of leading builders” */}
            Where dreams meet designs, and designs become reality.
          </div>
          <div className="text-gray-600 body-title inter-400 my-3">
            {/* Select from a collection of top-notch designs created by our professionals for your home interiors */}
            Discover the Finest Executable Designs for India's Premium Spaces, Crafted by Top Designers
          </div>
          <Button
            className="text-white body-text2 inter-600 mb-4"
            style={{ borderRadius: 3 }}
            onClick={() => navigate("/projects")}
          >
            Explore Inspirations!
          </Button>
          {card_info && (
            <HeroBanner items={recommendedProjects.slice(0, 3)} card_info={card_info} />
          )}
        </Container>
        {small && <>
          {/* <div
            className="h4 inter-600 w-100 mt-2"
            style={{ paddingTop: "10px" }}
          >
            Here's What We Do!
          </div> */}
          <Button
            className="d-flex justify-content-center align-items-center text-center border-0 mt-1 p-2"
            style={{ borderRadius: "50%", background: "#F1F3F4" }}
            ref={targetRef}
            onClick={() =>
              targetRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            <IoIosArrowDown size={20} color="#3C4043" />
          </Button>
        </>}
      </Container>
      <Container fluid className="bg-gray-25 py-5">
        <Container
          className="d-flex flex-column justify-content-center align-items-center text-center"
          style={{ minHeight: large ? "90vh" : "70vh" }}
        >
          <div
            className="h2 text-center inter-600 m-0"
          // style={{ padding: "0 10%" }}
          >
            The Best Brand Collaborations For Real Home Interior Inspirations
          </div>
          <div className="text-gray-600 body-title inter-400 my-3">
            Reducing premium interior cost by 20-30% to give you the best brands at great prices!
          </div>
          <div>
            <Button
              className="inter-500 text-white mb-4"
              style={{ borderRadius: 3 }}
              onClick={() => navigate("/brands")}
            >
              Explore Our Brands Partners!
            </Button>
          </div>
          <Breakpoint large up>
            {brands && (
              <Banners
                items={brands?.map((item) => ({
                  id: item.id,
                  title: item?.company_name,
                  image: item?.image,
                }))}
                speed={2000}
              />
            )}
          </Breakpoint>
          <BrandOnInspirations recommendedProjects={recommendedProjects} />
          <Breakpoint medium down>
            <Row className="mt-3 row-cols-2">
              {brands && brands?.slice(0, 4)?.map((item, index) => {
                return (
                  <Col key={index} className={`${index % 2 === 0 ? "pe-1" : "ps-1"}`}>
                    <Card
                      className="d-flex justify-content-center align-items-center my-1 shadow-none border-gray-100"
                      style={{ height: 42, borderRadius: 3 }}
                      onClick={() => navigate(`/brands/${item.slug}`)}
                    >
                      <Image
                        src={item?.image}
                        alt={item?.title}
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain"
                        }}
                      />
                    </Card>
                  </Col>
                )
              })}
            </Row>
            <div className="text-gray-600 body-title">and many more..</div>
          </Breakpoint>
        </Container>
      </Container>
      <ArchitectsForDisplay recommendedArchitects={recommendedArchitects} />
      <HowItWorks />
      <Container className="my-5 py-5 d-flex flex-column justify-content-center align-items-center">
        <div className="h2 inter-600 text-center">
          Streamline your home interior process through Interiokeys!
        </div>
        <div className="text-gray-600 body-title inter-400 text-center mt-1 mb-3">
          At Interiokeys, you can choose from the 2 types of services provided.
        </div>
        <ServiceCard />
      </Container>
      <Container fluid className="bg-gray-25 py-5 my-5">
        <Container className="d-flex flex-column justify-content-center align-items-center text-center">
          <div className="h2 inter-600 text-center">
            Trending Projects in your City!
          </div>
          <div className="text-gray-600 body-title inter-400 text-center mt-1 mb-3">
            At Interiokeys, you can choose from the 2 types of services provided.
          </div>
          {/* <Button
            variant="info"
            className="d-inline-flex align-items-center inter-600 text-primary py-2 px-3 my-3"
            style={{ borderRadius: 3, cursor: "default" }}
          >
            <FaStar size={20} className="me-2" />
            Interiokeys Recommended
          </Button> */}
          {recommendedProjects && (
            <div className="w-100">
              <Slider {...sliderSettings}>
                {recommendedProjects?.map((project, index) => (
                  <div key={index} className="d-flex justify-content-center p-1">
                    <ProjectCard
                      id={project.id}
                      slug={project.slug}
                      project_name={project.project_name}
                      image={project.rooms[0]?.media[0]?.file}
                      type={`${project.bhk}, ${project.project_area}`}
                      architect={project?.company_name}
                      builders={project.property_data?.builder_data?.company_name}
                      boxShadow="none"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
          {/* <Button
          className="body-title inter-600 text-white py-2 px-3"
          style={{ borderRadius: 3 }}
          onClick={() => navigate("/projects")}
        >
          Inspirations From Your City
        </Button> */}
        </Container>
      </Container>
      <Container className="d-flex justify-content-center align-items-center my-5 py-5">
        <div className="w-100">
          <h2 className="text-start inter-600 mb-4">
            Listen to What Our Clients Say!
          </h2>
          <h3 className="inter-600">Interiokeys Clients</h3>
          <Row className="mb-4">
            {clientTestimonials && clientTestimonials?.length > 0 ? (
              clientTestimonials?.map((testimonial, index) => (
                <Col key={index} md={4} className="mb-3">
                  <TestimonialCard testimonial={testimonial} />
                </Col>
              ))
            ) : (
              <div
                className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                style={{ height: 140 }}
              >
                No testimonials available
              </div>
            )}
          </Row>
          <h3 className="inter-600">Interiokeys Partners</h3>
          <Row>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {builderTestimonials && (
                <VerticalSlider items={builderTestimonials} rtl={false} />
              )}
              <span className="inter-600 mt-4">Builders</span>
            </Col>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {architectTestimonials && (
                <VerticalSlider items={architectTestimonials} rtl={true} />
              )}
              <span className="inter-600 mt-4">Architects</span>
            </Col>
            <Col
              lg={4}
              md={4}
              className="p-0 d-flex flex-column justify-content-center align-items-center"
            >
              {brandTestimonials && (
                <VerticalSlider items={brandTestimonials} rtl={false} />
              )}
              <span className="inter-600 mt-4">Brands</span>
            </Col>
          </Row>
        </div>
      </Container>
    </Container>
  );
};

export default Home;
