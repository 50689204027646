import React from "react";
import Dropzone from "react-dropzone";
import "./drop-file-input.css";
import { FiUpload } from "react-icons/fi";

const DropFileInput = ({
  type = "",
  files = [],
  onFileChange,
  allowMultiple = true,
  className,
  style,
  allowOnlyImages = false,
}) => {

  const onDrop = (acceptedFiles) => {
    const updatedList = allowMultiple
      ? [...files, ...acceptedFiles]
      : [acceptedFiles[0]];
    onFileChange(updatedList);
  };

  return (
    <>
      {((!allowMultiple && files.length === 0) || allowMultiple) && (
        <Dropzone
          onDrop={onDrop}
          accept={
            allowOnlyImages
              ? {
                "image/*": [".png", ".gif", ".jpeg", ".jpg", ".webp"],
              }
              : {}
          }
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            <div
              className={`drop-file-input position-relative d-flex justify-content-center align-items-center border border-dashed ${className}`}
              style={{
                width: "100%",
                height: 240,
                borderRadius: 8,
                ...style,
              }}
              onClick={open}
            >
              <div
                className="drop-file-input__label d-flex flex-column justify-content-center align-items-center h-100"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <div className="text-center w-100">
                    Drop the files here ...
                  </div>
                ) : (
                  <div className="text-center">
                    <FiUpload size={24} className="text-dark mb-2" />
                    <p className="body-text2">
                      Drop all files here or{" "}
                      <u className="text-primary">click to upload</u>
                      {type === "property" &&
                        <div className="d-flex flex-column body-text3 text-gray-600 mt-2">
                          <span>Zip Files (.zip)</span>
                          <span>Autocad drawings (.dwg)</span>
                          <span>Images (.jpg/.jpeg/.png)</span>
                          <span>Sketchup models (.skp)</span>
                        </div>
                      }
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </Dropzone>
      )}
    </>
  );
};

export default DropFileInput;
