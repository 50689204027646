import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaRegHeart, FaStar } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";
import Review from "components/Review/Review";
import * as authActions from "reduxFiles/actions/authActions";
import ProjectCard from "components/Cards/ProjectCard";
import { useSelector } from "react-redux";
import { notify } from "utilities/notifications/Notify";

const DesignerProfile = () => {
    const navigate = useNavigate();
    const { detailedSlug } = useParams();
    const user = useSelector((state) => state.auth.user);

    const [professionalDetails, setProfessionalDetails] = useState(null);
    const [projects, setProjects] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [showShare, setShowShare] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [isLiked, setIsLiked] = useState(false);

    const fetchArchitectsBySlug = async () => {
        setLoading(false);
        setError("");
        try {
            const response = await authActions.fetchArchitectByUser(user.id);
            if (response.data) {
                if (response.data.results[0]?.id) {
                    fetchProjects(response.data.results[0].id);
                }
                setProfessionalDetails(response.data.results[0]);
            } else {
                setError(
                    "Error while loading professionals's details. Try again later!"
                );
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(true);
    };

    const fetchProjects = async (id) => {
        setLoading(true);
        try {
            const response = await authActions.fetchProjectsByArchitect(id);
            if (response.data) {
                setProjects(response.data?.results);
            } else {
                setError("Error while loading project details");
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const checkProfessionalIfSaved = async () => {
        try {
            const response = await authActions.checkProfessionalIfSaved(
                user.id,
                professionalDetails.id
            );
            if (response) {
                setIsLiked(true);
            } else {
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSaveProfessional = async () => {
        try {
            const response = await authActions.saveProfessional(
                user.id,
                professionalDetails.id
            );
            if (response.data) {
                setIsLiked(true);
                notify("Professional saved successfully", "success");
            } else {
                notify("Failed to save professional, try again later", "success");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleUnsaveProfessional = async () => {
        try {
            const response = await authActions.unsaveProfessional(
                user.id,
                professionalDetails.id
            );
            if (response) {
                setIsLiked(false);
                notify("Professional unsaved successfully", "success");
            } else {
                notify("Failed to unsave professional, try again later", "success");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (user.id) {
            fetchArchitectsBySlug();
        }
    }, [user]);

    useEffect(() => {
        if (professionalDetails?.id) {
            checkProfessionalIfSaved();
        }
    }, [professionalDetails]);

    return (
        <Container fluid className="mt-5 pt-4 p-0">
            {loading ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "50vh" }}
                >
                    <Spinner />
                </div>
            ) : error ? (
                <div className="h3 inter-600 text-center">{error}</div>
            ) : (
                <div>
                    <div className="bg-gray-25 geist">
                        <Container>
                            <Row className="py-3 border-bottom m-0">
                                <Col lg={9} xl={10}>
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="h2 inter-600 text-capitalize">
                                            {professionalDetails?.user_data.full_name}
                                        </div>
                                        <div className="d-flex ms-auto">
                                            <Button
                                                className="d-flex justify-content-center align-items-center social-btn py-2"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowShare(true);
                                                }}
                                            >
                                                <FiShare2 size={18} />
                                            </Button>
                                            {user && (
                                                <Button
                                                    className={`d-flex justify-content-center align-items-center social-btn py-2 ms-2 ${isLiked ? "bg-primary text-white" : ""
                                                        }`}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        isLiked
                                                            ? handleUnsaveProfessional()
                                                            : handleSaveProfessional();
                                                    }}
                                                >
                                                    <FaRegHeart size={18} />
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="body-text inter-600 text-capitalize">
                                        {professionalDetails?.company_name ??
                                            detailedSlug?.split("-").join(" ")}
                                    </div>
                                    <div className="d-flex align-items-center body-text inter-600">
                                        Ratings: {professionalDetails?.rating}
                                        <FaStar color="#D95656" />
                                    </div>
                                    <div>
                                        <span className="body-text inter-600">Location:</span>{" "}
                                        {professionalDetails?.location}
                                    </div>
                                    <div>
                                        <span className="body-text inter-600">
                                            Number of cities:
                                        </span>{" "}
                                        {professionalDetails?.no_of_cities}
                                    </div>
                                    <div>
                                        <span className="body-text inter-600">
                                            Typical Job Cost:
                                        </span>{" "}
                                        {professionalDetails?.project_cost_minimum}-
                                        {professionalDetails?.project_cost_maximum}
                                    </div>
                                    <div>
                                        <span className="body-text inter-600">Designed for:</span>{" "}
                                        {professionalDetails?.project_names}
                                    </div>
                                    <Button
                                        className="text-white mt-3 mb-4"
                                        onClick={() => {
                                            if (professionalDetails?.id) {
                                                navigate(`/consultation/architect/${detailedSlug}`, {
                                                    state: { architect: professionalDetails },
                                                });
                                            } else {
                                                notify(
                                                    "Professional undefined, try again later",
                                                    "error"
                                                );
                                            }
                                        }}
                                    >
                                        Book Free Consultaion
                                    </Button>
                                </Col>
                                <Col lg={3} xl={2}>
                                    <div className="d-flex justify-content-start justify-content-lg-center">
                                        <div
                                            className="d-flex justify-content-center align-items-center bg-white ms-lg-auto rounded-circle overflow-hidden"
                                            style={{ width: 150, height: 150 }}
                                        >
                                            <img
                                                className="border rounded-circle"
                                                width={150}
                                                height={150}
                                                style={{
                                                    border: "1px solid #D0D5DD",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                }}
                                                src={professionalDetails?.user_data?.profile_picture}
                                                alt="Architect's Profile"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Container className="py-3">
                        <div className="mb-3">
                            <div className="geist body-title mb-3">
                                <span className="inter-700">
                                    {projects?.length} real project designs
                                </span>{" "}
                                created
                            </div>
                            {projects?.length > 0 ? (
                                <Row>
                                    {projects?.map((item, index) => (
                                        <Col
                                            lg={4}
                                            key={index}
                                            className="d-flex justify-content-around my-3"
                                        >
                                            <ProjectCard
                                                {...item}
                                                id={item.id}
                                                project_name={item?.project_name}
                                                image={item?.rooms[0]?.media[0]?.file}
                                                // type={`${item?.bhk}, Block ${item?.block}, ${item?.project_area}`}
                                                type={`${item?.bhk}, ${item?.project_area}`}
                                                architect={item?.company_name}
                                                location={item?.property_data?.location}
                                                builders={
                                                    item?.property_data?.builder_data?.company_name
                                                }
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <div
                                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                    style={{ height: 160 }}
                                >
                                    No projects available
                                </div>
                            )}
                        </div>
                        <div className="d-flex justify-content-end">
                            <FaStar size={24} color="#d3d3d3" className="me-2" />
                            <FaStar size={24} color="#d3d3d3" className="me-2" />
                            <FaStar size={24} color="#d3d3d3" className="me-2" />
                            <FaStar size={24} color="#d3d3d3" className="me-2" />
                            <FaStar size={24} color="#d3d3d3" className="" />
                        </div>
                        <div className="mb-4">
                            <div className="h3 inter-600">Add Review</div>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="I love interiokeys because..."
                                />
                            </Form.Group>
                            <div className="w-100 my-3 d-flex justify-content-end">
                                <Button className="text-white">Submit Review</Button>
                            </div>
                            <div className="h4 inter-600">Reviews and Ratings</div>
                            {reviews?.length > 0 ? (
                                reviews.map((review, index) => (
                                    <Review key={index} {...review} />
                                ))
                            ) : (
                                <div
                                    className="d-flex justify-content-center align-items-center bg-gray-25 rounded"
                                    style={{ height: 160 }}
                                >
                                    No reviews available
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            )}
        </Container>
    );
};

export default DesignerProfile;
