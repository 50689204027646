import { useEffect, useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { truncateText } from "utilities/truncateText";

const InspirationMegaMenu = ({ showInspirations, setShowInspirations }) => {
  const navigate = useNavigate();
  const projectsRooms = [
    { id: 1, title: "All Projects", slug: "projects" },
    { id: 2, title: "All Spaces", slug: "spaces" },
  ]
  const [activeTab, setActiveTab] = useState(projectsRooms[0]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      switch (activeTab.slug) {
        case "projects":
          console.log(activeTab.slug);
          const response = await authActions.fetchAllProperties();
          if (response.data) {
            setData(response.data);
          }
          break;
        case "spaces":
          console.log(activeTab.slug);
          const response1 = await authActions.fetchRoomTypes();
          if (response1.data) {
            setData(response1.data);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (activeTab) {
      fetchData()
    }
  }, [activeTab])

  return (
    <Card
      className={`${showInspirations ? "position-absolute huge-dropdown-container d-flex p-0 shadow-none" : "d-none"}`}
      style={{ left: 0, top: 70 }}
    >
      <Card.Body
        className="p-0"
        style={{ backgroundColor: "#FFFFFF", borderRadius: 5 }}
      >
        <div className="pt-2" style={{ padding: "0px 50px 30px" }}>
          <div className="body-text2 d-flex mb-4">
            {projectsRooms.map((item) => {
              return (
                <div className="me-2 cursor-pointer" onClick={() => setActiveTab(item)}>
                  <div className={`my-1 mx-2 inter-500 ${item.slug === activeTab.slug ? "text-primary" : "text-gray-900"}`}>{item.title}</div>
                  <div className={`mt-1 w-100 ${item.slug === activeTab.slug ? "bg-primary" : ""}`} style={{ height: 2 }} />
                </div>
              )
            })}
          </div>
          {loading ?
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '10vh' }}>
              <Spinner />
            </div>
            :
            <>
              <div
                className="body-text3 d-flex align-items-center inter-500 mb-2"
                onClick={() => {
                  if (activeTab.slug === "projects") {
                    navigate(`/projects`)
                  } else {
                    navigate(`/spaces`)
                  }
                }}
              >
                <span className="me-2">View All {activeTab.slug === "projects" ? "Projects" : "Spaces"}</span>
                <FaChevronRight size={12} />
              </div>
              <div className="d-flex flex-wrap">
                {data?.length && data?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="d-flex flex-column align-items-center body-text3 nav-link cursor-pointer me-2"
                      onClick={() => {
                        setShowInspirations(false)
                        if (activeTab.slug === "projects") {
                          navigate(`/projects?property=${item.slug}`)
                        } else {
                          navigate(`/spaces?room-type=${item.slug}`)
                        }
                      }}
                    >
                      <Image
                        src={item?.property_img || item?.image}
                        alt={item?.property_name || item?.title}
                        className="small-image"
                        width="90px"
                        height="80px"
                      />
                      <div className="p-1">{truncateText(item?.property_name || item?.title, 13)}</div>
                    </div>
                  );
                })}
              </div>
            </>
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default InspirationMegaMenu;
