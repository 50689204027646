import { useState } from "react";
import { InputGroup, Modal, Form, Button, Spinner, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { interiokeys_full_logo } from "assets";

import * as authActions from "reduxFiles/actions/authActions";
// import { login } from "../../reducer/testAuthSlice";
import { login } from "reduxFiles/reducer/authSlices";
import { notify } from "utilities/notifications/Notify";
import { validateEmail } from "utilities/validateEmail";

import FacebookGoogleLogin from "./FacebookGoogleLogin";
import ForgotPassword from "./ForgotPassword";

import { LuEyeOff, LuEye, LuMail } from "react-icons/lu";
import { FaAngleRight } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const LoginModal = ({ showLoginModal, setShowLoginModal, isHomeOwner, setIsHomeOwner }) => {
  const [formType, setFormType] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1300px)",
  });

  const handleLogin = async () => {
    setLoading(true)
    if (validateEmail(email)) {
      const data = {
        username: email,
        password: password,
      };
      try {
        const response = await authActions.login(data);
        if (response.data) {
          dispatch(login(response.data));
          setShowLoginModal(false);
          setEmail("");
          setPassword("");
          navigate("/");
          notify("Logged in successfully!", "success");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      notify("Please enter a valid email id!", "error");
    }
    setLoading(false)
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await authActions.sendResetOtp({ email: email });
    if (response) {
      switch (response.status) {
        case "SUCCESSFUL":
          notify(response.msg, "success");
          setFormType("reset-password");
          break;
        default:
        // notify("Error while sending OTP, Try again later", "error");
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      className="d-flex justify-content-center align-items-center"
      dialogClassName="custom-width-modal"
      show={showLoginModal}
      onHide={() => {
        setShowLoginModal(false);
        setFormType("login");
        setIsHomeOwner(true);
      }}
    >
      <div className="py-3 px-4">
        <Modal.Header className="px-0 pb-0 position-relative" style={{ border: 0 }}>
          {formType === "forgot-password" ?
            <div className="cursor-pointer position-absolute icon-hover" onClick={() => setFormType("login")}>
              <IoIosArrowBack size={20} />
            </div>
            : formType === "reset-password" ?
              <div className="cursor-pointer position-absolute icon-hover" onClick={() => setFormType("forgot-password")}>
                <IoIosArrowBack size={20} />
              </div>
              : <></>
          }
          {formType === "login" ?
            <div
              className="cursor-pointer position-absolute icon-hover"
              style={{ right: 0 }}
              onClick={() => {
                setFormType("login")
                setShowLoginModal(false);
              }}
            >
              <IoClose size={20} />
            </div>
            :
            <></>
          }
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <div
              className="inter-700 text-gray-900"
              style={{ fontSize: "30px", color: "black" }}
            >
              <Image
                src={interiokeys_full_logo}
                alt="Interiokeys logo"
                style={{ maxWidth: !isLargeScreen ? 180 : 150 }}
              />
            </div>
            <div className="body-text1 inter-600">
              {(isHomeOwner && formType === "login") ?
                "Homeowners"
                : (!isHomeOwner && formType === "login") ?
                  "Professionals"
                  : formType === "forgot-password" ?
                    "Reset Password"
                    :
                    ""
              }
            </div>
            <div className="body-text2 text-gray-600">
              {formType === "login" ?
                "Select from a collection of top-notch designs created by our professionals for your home interiors"
                : formType === "forgot-password" ?
                  "Reset your password using an OTP sent to your Email ID."
                  : formType === "reset-password" ?
                    `An email has been sent to ${email} with the otp.`
                    :
                    ""
              }
            </div>
          </div>
          {formType === "login" ?
            <Form className="my-3">
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Email ID<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
                  >
                    <LuMail size={20} color="#1A1C1F" />
                  </InputGroup.Text>
                  <Form.Control
                    className="border-start-0 p-2"
                    type="email"
                    name="email"
                    required
                    placeholder="example@gmail.com"
                    value={email}
                    style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="mb-1 body-text2 inter-600">
                  Password<sup style={{ color: "red" }}>*</sup>
                </Form.Label>
                <InputGroup>
                  <InputGroup.Text
                    className="cursor-pointer"
                    style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <LuEye size={20} /> : <LuEyeOff size={20} />}
                  </InputGroup.Text>
                  <Form.Control
                    className="border-start-0 p-2"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    required
                    placeholder="**********"
                    value={password}
                    style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <div
                  className="body-text3 text-gray-600 mt-1 mb-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEmail("")
                    setPassword("")
                    setFormType("forgot-password")
                  }}
                >
                  Forgot Password?
                </div>
              </Form.Group>

              <Button
                onClick={() => handleLogin()}
                variant="primary"
                className="py-2 body-text2 border-0"
                style={{
                  fontWeight: "600",
                  color: "#fff",
                  width: "100%",
                  borderRadius: 3
                }}
              >
                {loading && <Spinner animation="border" size="sm" />}{" "}
                Login
              </Button>

              <div className="body-text3 text-gray-900 text-center my-2">
                Not a member?{" "}
                <span
                  className="inter-500 body-text2 cursor-pointer"
                  onClick={() => {
                    navigate(`/signup#${isHomeOwner ? "homeowner" : "professional"}`)
                    setShowLoginModal(false)
                  }}
                >
                  {isHomeOwner ? "Homeowner" : "Professional"} Registration
                </span>
              </div>
            </Form>
            : formType === "forgot-password" ?
              <Form className="my-3">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1 body-text2 inter-600">
                    Email ID<sup style={{ color: "red" }}>*</sup>
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text
                      style={{ backgroundColor: "white", border: "1px solid #BDC1C6", borderRadius: 3 }}
                    >
                      <LuMail size={20} color="#1A1C1F" />
                    </InputGroup.Text>
                    <Form.Control
                      className="border-start-0 p-2"
                      type="email"
                      name="email"
                      required
                      placeholder="example@gmail.com"
                      value={email}
                      style={{ border: "1px solid #BDC1C6", borderRadius: "0px 3px 3px 0px" }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                {/* <Form.Group className="mb-3">
                  <div
                    className="body-text3 text-gray-900 mt-1 mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEmail("")
                      setFormType("login")
                    }}
                  >
                    Back to Login!
                  </div>
                </Form.Group> */}
                <Button
                  onClick={(e) => handleSendOtp(e)}
                  variant="primary"
                  className="py-2 body-text2 border-0"
                  style={{
                    fontWeight: "600",
                    color: "#fff",
                    width: "100%",
                    borderRadius: 3
                  }}
                >
                  {loading && <Spinner animation="border" size="sm" />}{" "}
                  Send OTP
                </Button>
              </Form>
              :
              <ForgotPassword email={email} setFormType={setFormType} />
          }

          {formType === "login" &&
            <>
              <FacebookGoogleLogin userType={isHomeOwner ? "customer" : "professional"} />
              <div className="body-text2 text-gray-900">
                Are you a {isHomeOwner ? "Professional" : "Homeowner"}?
              </div>
              <Button
                className="py-2 mt-2 border-0 w-100 d-flex justify-content-between align-items-center"
                style={{
                  fontWeight: "600",
                  backgroundColor: "#F1F3F4",
                  color: "#5F6368",
                  borderRadius: 3
                }}
                onClick={() => {
                  setIsHomeOwner(!isHomeOwner);
                }}
              >
                <span className="body-text2">{isHomeOwner ? "Professionals" : "Homeowner"} Login</span>
                <FaAngleRight />
              </Button>
            </>
          }
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default LoginModal;
