import {
  wcu_img1,
  wcu_img2,
  wcu_img3,
  wcu_img4,
  wcu_img5,
  wcu_img6,
  wcu_img7,
} from "assets";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const WhyChooseUs = () => {
  const large = useMediaQuery({ query: "(min-width: 992px)" });

  return (
    <Container fluid className="mt-5 pt-4 px-0">
      <section className="d-flex flex-column alignt-items-center">
        <div className="h1 inter-600 text-center mb-lg-5 mb-4">Why Choose Us?</div>
        <Row
          className="align-items-center py-5"
          style={{
            background: "#F0F3F9",
            // padding: `3% ${large ? "20%" : "10%"}`,
            paddingLeft: `${large ? "20%" : "10%"}`,
            paddingRight: `${large ? "20%" : "10%"}`,
          }}
        >
          <Col sm={4}>
            <img className="img-fluid" src={wcu_img1} alt="" />
          </Col>
          <Col sm={8} className="mt-lg-0 mt-3">
            <div className="h3 inter-600 text-primary">
              10k+ real inspirations
            </div>
            <div className="body-text2 geist">
              All our designs are completely executable for existing layouts of
              premium builders. We show our customers how versatile their space
              can be designed.
            </div>
          </Col>
        </Row>
        <Row
          className="align-items-center py-5"
          style={{
            paddingLeft: `${large ? "20%" : "10%"}`,
            paddingRight: `${large ? "20%" : "10%"}`
          }}
        >
          <Col sm={4}>
            <img className="img-fluid" src={wcu_img2} alt="" />
          </Col>
          <Col sm={8} className="mt-lg-0 mt-3">
            <div className="h3 inter-600 text-primary">
              Steamlining an unorganized industry process
            </div>
            <div className="body-text2 geist">
              We plan on streamlining the whole interior process with complete
              transparency at each step so the client can ensure their money is
              in safe hands. An open platform where client can get knowledge,
              get quotes, compare costs and choose the best for him
            </div>
          </Col>
        </Row>
        <Row
          className="align-items-center py-5"
          style={{
            background: "#F0F3F9",
            paddingLeft: `${large ? "20%" : "10%"}`,
            paddingRight: `${large ? "20%" : "10%"}`,
          }}
        >
          <Col sm={4}>
            <img className="img-fluid" src={wcu_img3} alt="" />
          </Col>
          <Col sm={8} className="mt-lg-0 mt-3">
            <div className="h3 inter-600 text-primary">
              Reducing premium interior cost by 20-30%
            </div>
            <div className="body-text2 geist">
              <div>1. You just pay for your interiors.</div>
              <div>
                2. Same design, same quality in a budget suitable to you by
                choosing between our multiple vendors
              </div>
            </div>
          </Col>
        </Row>
        <Row
          className="align-items-center py-5"
          style={{
            paddingLeft: `${large ? "20%" : "10%"}`,
            paddingRight: `${large ? "20%" : "10%"}`
          }}
        >
          <Col sm={4}>
            <img className="img-fluid" src={wcu_img4} alt="" />
          </Col>
          <Col sm={8} className="mt-lg-0 mt-3">
            <div className="h3 inter-600 text-primary">
              Top Quality Products
            </div>
            <div className="body-text2 geist">
              We help connect good quality brands with customers by marketing
              their products in our design inspirations. From local to
              international, we plan on being a one-stop source for all top
              quality products
            </div>
          </Col>
        </Row>
        <Row
          className="align-items-center py-5"
          style={{
            background: "#F0F3F9",
            paddingLeft: `${large ? "20%" : "10%"}`,
            paddingRight: `${large ? "20%" : "10%"}`,
          }}
        >
          <Col sm={4}>
            <img className="img-fluid" src={wcu_img5} alt="" />
          </Col>
          <Col sm={8} className="mt-lg-0 mt-3">
            <div className="h3 inter-600 text-primary">
              Extended Warranty
            </div>
            <div className="body-text2 geist">
              Genuine warranty and guaranty with +2 years extension by Interiokeys
              on existing warranty and great customer support
            </div>
          </Col>
        </Row>
        <Row
          className="align-items-center py-5"
          style={{
            paddingLeft: `${large ? "20%" : "10%"}`,
            paddingRight: `${large ? "20%" : "10%"}`
          }}
        >
          <Col sm={4}>
            <img className="img-fluid" src={wcu_img6} alt="" />
          </Col>
          <Col sm={8} className="mt-lg-0 mt-3">
            <div className="h3 inter-600 text-primary">
              Expert Partners
            </div>
            <div className="body-text2 geist">
              We provide talented designers a platform to showcase their work.
              Everything from products research to selection is managed by our
              team of professioanls so designers can focus on creating interior
              inspiration
            </div>
          </Col>
        </Row>
        <Row
          className="align-items-center py-5"
          style={{
            background: "#F0F3F9",
            paddingLeft: `${large ? "20%" : "10%"}`,
            paddingRight: `${large ? "20%" : "10%"}`,
          }}
        >
          <Col sm={4}>
            <img className="img-fluid" src={wcu_img7} alt="" />
          </Col>
          <Col sm={8} className="mt-lg-0 mt-3">
            <div className="h3 inter-600 text-primary">
              Genuine Rating System
            </div>
            <div className="body-text2 geist">
              All our partners are accountable through the rating system and
              strive to deliver the best to the clients. Ratings lower than 4/5
              are not tolerated
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default WhyChooseUs;
