import ShareModal from "components/General/ShareModal";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FaRegHeart } from "react-icons/fa6";
import { FiShare2 } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as authActions from "reduxFiles/actions/authActions";
import { notify } from "utilities/notifications/Notify";

const SpaceCard = ({
  id,
  slug,
  image,
  title,
  type,
  projectId,
  project,
  area,
  setShow,
  setRoomData,
  boxShadow,
  imgHeight = "300px",
  className,
  style,
  onClick,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const [showShare, setShowShare] = useState(false);
  const [hover, setHover] = useState(false);
  const [isLiked, setIsLiked] = useState(false);

  const checkRoomIfSaved = async () => {
    try {
      const response = await authActions.checkRoomIfSaved(user.id, id);
      console.log(response);
      if (response) {
        setIsLiked(true);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveRoom = async () => {
    try {
      const response = await authActions.saveRoom(user.id, id);
      if (response) {
        setIsLiked(true);
        notify("Space saved successfully", "success");
      } else {
        notify("Failed to save space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnsaveRoom = async () => {
    try {
      const response = await authActions.unsaveRoom(user.id, id);
      if (response) {
        setIsLiked(false);
        notify("Space unsaved successfully", "success");
      } else {
        notify("Failed to unsave space, try again later", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkRoomIfSaved();
  }, []);

  return (
    <>
      <Card
        className={`cursor-pointer w-100 py-0 px-0 border rounded shadow-none overflow-hidden position-relative ${hover ? "border-gray-300" : "border-gray-25"
          } ${className}`}
        style={{
          boxShadow: boxShadow,
          // backgroundColor: hover ? "rgba(0, 0, 0, 0.1)" : "",
          ...style,
        }}
        onClick={onClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Card.Img
          className="img-fluid"
          variant="top"
          src={image}
          style={{
            maxHeight: imgHeight,
            minHeight: imgHeight,
            objectFit: "cover",
          }}
        />
        <div
          className="d-flex position-absolute"
          style={{ zIndex: 999, right: 15, top: 10 }}
        >
          <Button
            className="d-flex justify-content-center align-items-center share-like-btn p-0"
            onClick={(e) => {
              e.stopPropagation();
              setShowShare(true);
            }}
          >
            <FiShare2 size={20} color="#3C4043" />
          </Button>
          {user && (
            <Button
              className={`d-flex justify-content-center align-items-center share-like-btn ms-2 p-0 ${isLiked ? "bg-primary text-white" : ""
                }`}
              onClick={(e) => {
                e.stopPropagation();
                isLiked ? handleUnsaveRoom() : handleSaveRoom();
              }}
            >
              <FaRegHeart size={20} color={isLiked ? "#FFFFFF" : "#3C4043"} />
            </Button>
          )}
        </div>
        <Card.Body
          className="p-2 d-flex flex-column align-items-start"
          onClick={() => navigate(`/projects/${slug}`)}
        >
          <Card.Title
            className={`inter-700 mb-0 ${hover ? "text-primary" : ""}`}
            style={{ fontSize: "16px" }}
          >
            {title}
          </Card.Title>
          {/* <Card.Title className={`inter-700 mb-0 ${hover ? "text-primary" : ""}`} style={{ fontSize: "16px" }}> {title} </Card.Title> */}
          <div className="body-text2 inter-400 d-flex flex-column align-items-start">
            {type ? (
              <span className="ms-0">Type: {type}</span>
            ) : area ? (
              <span className="ms-0">Area: {area}</span>
            ) : (
              project && (
                <span className="ms-0">Project: {project?.project_name}</span>
              )
            )}
          </div>
        </Card.Body>
      </Card>
      <ShareModal
        show={showShare}
        onHide={() => setShowShare(false)}
        id={id}
        image={image}
        title={`${type} / ${project?.project_name}`}
      // urlPath={`/spaces/${slug}`} // #defaultUrl
      />
    </>
  );
};

export default SpaceCard;
